<template>
  <div class="type_area customer_home">
    <!-- 公共侧边栏 -->
    <div class="sidebar_box">
      <div class="sidebar_item" :class="{active: currentUrl == item.url}" @click="pageJump(item.url,item.name)" v-for="item in sidebarList" :key="item.name">{{item.name}}</div>
    </div>

    <!-- 主要区域容器 -->
    <div class="customer_main">
      <div class="bread_box">
        <span class="index" @click="$router.push('/')">首页</span>
        <span> / 个人中心 / {{currentName}}</span>
      </div>
      <div>
        <router-view></router-view>
      </div>
    </div>
    <!-- 主要区域容器END -->
  </div>
</template>

<script>

export default {
  name: 'PersonalHome',
  data() {
    return {
      currentUrl: this.$route.path,
      currentName: '',
      sidebarList: [{
        name:'我的订单',
        url: '/personal/myOrder'
      },
      {
        name:'待客户经理审核',
        url: '/personal/auditingOrder'
      },
      // {
      //   name:'我的发票',
      //   url: '/personal/myInvoice'
      // },
      {
        name:'我的收藏',
        url: '/personal/myFavorites'
      },
      {
        name:'我的优惠券',
        url: '/personal/myCoupon'
      },
      {
        name:'采购历史',
        url: '/personal/purchaseHistory'
      },
      {
        name:'我的留言',
        url: '/personal/myMessage'
      },
      {
        name:'收货地址',
        url: '/personal/myAddress'
      },
      {
        name:'账号信息',
        url: '/personal/accountInfo'
      },
      {
        name:'修改密码',
        url: '/personal/changePassword'
      },
      {
        name:'安全退出',
        url: ''
      },
      ],
    };
  },
  watch: {
    // 监听路由对象的变化
    '$route'(to, from) {
      // 当路由发生变化时，你可以在这里处理你的逻辑
      this.currentUrl = this.$route.path
      this.sidebarList.forEach(item=>{
        if(item.url == this.currentUrl) this.currentName = item.name
      })
      // 你可以在这里根据路由做出相应的处理
    }
  },
  mounted() {
    this.currentUrl = this.$route.path
    this.sidebarList.forEach(item=>{
      if(item.url == this.currentUrl) this.currentName = item.name
    })
  },
  methods: {
    // 页面跳转
    pageJump(url,name){
      if(name == '安全退出'){
         this.$confirm('此操作将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.$api.visitorLogout().then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem('roleFrist')
            localStorage.removeItem('roleNumber')
            this.$router.push('/login')
          }
        });
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消退出'
          });          
        });
       
      }else{
        this.currentName = name
        this.currentUrl = url
        this.$router.push(url)
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.customer_home{
  display: flex;
  align-items: flex-start;
  margin-top: 18px;

  .sidebar_box{
    flex-shrink: 0;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 20px;

    .sidebar_item{
      width: 160px;
      box-sizing: border-box;
      padding: 12px 24px;
      background: #fff;
      font-size: 16px;
      color: #333333;
      cursor: pointer;

      &.active{
        background: #E7F5FF;
        color: #1A9DFF;
      }

      &:hover{
        color: #1A9DFF;
      }
    }
  }

  .customer_main{
    width: calc(100% - 180px);
    flex: 1;

    .bread_box{
      margin-bottom: 12px;
      font-size: 16px;
      color: #666666;
      padding-top: 9px;

      .index{
        &:hover{
          color: #1A9DFF;
          transition: 0.3;
          cursor: pointer;
        }
      }
    }
  }
}
</style>